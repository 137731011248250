
export default {
    name: "SpacerBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        margin() {
            const spacings = []
            const blok = this.blok
            spacings.push(this.toString(blok.mt, "t"))
            spacings.push(this.toString(blok.mr, "r"))
            spacings.push(this.toString(blok.mb, "b"))
            spacings.push(this.toString(blok.ml, "l"))
            spacings.push(this.toString(blok.md_mt, "t", "md"))
            spacings.push(this.toString(blok.md_mr, "r", "md"))
            spacings.push(this.toString(blok.md_mb, "b", "md"))
            spacings.push(this.toString(blok.md_ml, "l", "md"))
            return spacings.filter((n) => n)
        },
        style() {
            if (this.blok.maxWidth) {
                return {
                    maxWidth: this.blok.maxWidth + "px",
                    margin: this.blok.justify ? undefined : "0 auto",
                }
            }
            return {}
        },
        classes() {
            return {
                "mx-auto": !!this.blok.maxWidth,
                "d-flex flex-column": !!this.blok.maxWidth,
                [this.blok.justify]: !!this.blok.justify,
                [this.blok.customClasses]: !!this.blok.customClasses,
            }
        },
        hideClasses() {
            const breakpoints = this.blok.hideForBreakpoints
            if (!breakpoints?.length > 0) return
            return breakpoints.map((bp) => `hide--${bp}`)
        },
    },
    methods: {
        toString(value, direction, breakpoint) {
            if (!value) return
            const usePadding = value > 0 && this.blok.padding
            let prefix = usePadding ? "p" : "m"
            prefix += direction ?? "a"
            prefix += "-"
            if (breakpoint) {
                prefix += `${breakpoint}-`
            }
            if (value > 0) return prefix + value
            return prefix + "n" + Math.abs(value)
        },
    },
}
